var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          ref: "multipleTable",
          attrs: {
            data: _vm.data,
            "span-method": _vm.objectSpanMethod,
            border: "",
            stripe: "",
            "header-cell-style": { textAlign: "center" },
            "tooltip-effect": "dark"
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "商品ID", prop: "id", "min-width": "10%" }
          }),
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "name", "min-width": "18%" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "关联供应商SKU ID",
              prop: "spec_id",
              "min-width": "10%"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "供应商SKU名称",
              prop: "spec_name",
              "min-width": "18%"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "上游成本价（元）",
              prop: "sc_cost_price",
              "min-width": "10%"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatSkuPrice")(scope.row.sc_cost_price)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "上游价格变动（元）",
              prop: "update_float_price",
              "min-width": "12%"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.update_float_price > 0
                      ? _c("p", [
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-top",
                              staticStyle: { color: "#f56c6c" }
                            },
                            [
                              _vm._v(
                                "+" +
                                  _vm._s(
                                    _vm._f("formatSkuPrice")(
                                      scope.row.update_float_price
                                    )
                                  )
                              )
                            ]
                          )
                        ])
                      : scope.row.update_float_price < 0
                      ? _c("p", [
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-bottom",
                              staticStyle: { color: "#67c23a" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatSkuPrice")(
                                    scope.row.update_float_price
                                  )
                                )
                              )
                            ]
                          )
                        ])
                      : _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatSkuPrice")(
                                scope.row.update_float_price
                              )
                            )
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "客户成本价（元）",
              prop: "cost_price",
              "min-width": "12%"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatSkuPrice")(scope.row.cost_price)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "建议零售价（元）",
              prop: "sku_sale_price",
              "min-width": "10%"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatSkuPrice")(scope.row.sku_sale_price)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "更新时间",
              prop: "update_time",
              "min-width": "14%"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "10%" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small", plain: "" },
                        on: {
                          click: function($event) {
                            return _vm.changePrice(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("修改价格")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改价格  （老系统）",
            visible: _vm.dialogVisible,
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "spu-info" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.priceData,
                    "label-width": "90px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "商品 ID:" }
                    },
                    [_vm._v(_vm._s(_vm.priceData.id))]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "商品 名称:" }
                    },
                    [_vm._v(_vm._s(_vm.priceData.name))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "30px" } },
            [
              _c("p", { staticClass: "sku-title" }, [_vm._v("关联供应商SKU")]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.priceData.sku_infos }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序", type: "index", "min-width": "5%" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sc_name",
                      label: "供应商",
                      "min-width": "12%"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "供应商SKU ID",
                      "min-width": "13%"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "关联供应商SKU 名称",
                      "min-width": "20%"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "上游成本价（元）",
                      prop: "sc_cost_price",
                      "min-width": "12%"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { maxlength: "9" },
                              on: {
                                input: function($event) {
                                  return _vm.specifiPrice(
                                    $event,
                                    scope.$index,
                                    "sc_cost_price"
                                  )
                                }
                              },
                              model: {
                                value: scope.row.sc_cost_price,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "sc_cost_price", $$v)
                                },
                                expression: "scope.row.sc_cost_price"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cost_price",
                      label: "客户成本价(元)",
                      "min-width": "15%"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { maxlength: "9" },
                              on: {
                                input: function($event) {
                                  return _vm.specifiPrice(
                                    $event,
                                    scope.$index,
                                    "cost_price"
                                  )
                                }
                              },
                              model: {
                                value: scope.row.cost_price,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "cost_price", $$v)
                                },
                                expression: "scope.row.cost_price"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sale_price",
                      label: "建议零售价（元）",
                      "min-width": "15%"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { maxlength: "9" },
                              on: {
                                input: function($event) {
                                  return _vm.specifiPrice(
                                    $event,
                                    scope.$index,
                                    "sale_price"
                                  )
                                }
                              },
                              model: {
                                value: scope.row.sale_price,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "sale_price", $$v)
                                },
                                expression: "scope.row.sale_price"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "face_price",
                      label: "面值（元）",
                      "min-width": "15%"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: { maxlength: "9" },
                              on: {
                                input: function($event) {
                                  return _vm.specifiPrice(
                                    $event,
                                    scope.$index,
                                    "face_price"
                                  )
                                }
                              },
                              model: {
                                value: scope.row.face_price,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "face_price", $$v)
                                },
                                expression: "scope.row.face_price"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "推老板佣金", "min-width": "10%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                style: {
                                  color:
                                    _vm.formatCouponPrice(scope.row) > 0
                                      ? "#409EFF"
                                      : "#FF0033"
                                }
                              },
                              [_vm._v(_vm._s(_vm.formatCouponPrice(scope.row)))]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div"),
          _c(
            "div",
            { staticStyle: { color: "#ff0033", "margin-top": "20px" } },
            [
              _c("p", { staticStyle: { padding: "10px 0" } }, [
                _vm._v("PS:修改价格请注意客户成本价")
              ]),
              _c("p", [_vm._v("推老板佣金=客户成本价-上游成本价")])
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { display: "block", margin: "auto" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onSave }
                },
                [_vm._v("确认修改")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }